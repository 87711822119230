import { resources } from '../config';

export const getProducts = async (productCategoryId) => {

    console.log("getProducts");

    const headers = new Headers();
    const options = {
        method: 'GET',
        headers,
    };

    return fetch(`${resources.apiBikes.endpoint}/GetProducts/${productCategoryId && productCategoryId}`, options)
        .then((response) => response.json())
        .catch((error) => console.log(error));
};
