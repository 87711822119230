import { Navigate, createBrowserRouter, RouterProvider, useRouteError, location } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';


// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import BikePage from './pages/BikePage';
import ProductPage from './pages/ProductPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';

// services
import { getProducts } from "./services/fetchProducts";

// ----------------------------------------------------------------------

export default function App() {


  const routes = createBrowserRouter([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'bike', element: <BikePage /> },
        { path: 'product/:productCategoryId?', element: <ProductPage />,
          shouldRevalidate: () => { 
            console.log("shouldRevalidate");
            return true; 
          },
          loader: ({ params }) => {
            return getProducts(params.productCategoryId ? params.productCategoryId : 0);
        }},
        { path: 'blog', element: <BlogPage /> },
      ],
      errorElement: <ErrorBoundary />
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  function ErrorBoundary() {
    const error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <div>Error: {error.message}</div>;
  }  


  return (
    <HelmetProvider>
      <ThemeProvider>
        <RouterProvider router={routes}>
          <ScrollToTop />
          <StyledChart />
        </RouterProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}
