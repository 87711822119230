import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';

import Iconify from '../iconify';

// ----------------------------------------------------------------------

const StyledMessage = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function CompatibilityDialog(props) {
    const { onClose, title, compatible, message, open, bike, product } = props;
  
    const handleClose = () => {
      onClose();
    };
  
    return (
      <Dialog onClose={handleClose} open={open}>

        <DialogTitle>{title}</DialogTitle>
        <Box sx={{ mb: 2.5, mx: 2.5 }}>
            
          <StyledMessage>

            {/* icons from https://icon-sets.iconify.design/solar/shield-check-bold-duotone/ */}
            {compatible ? ( 
              <Iconify icon={'solar:shield-check-bold-duotone'} sx={{ mr: 2, color: 'success.main' }} width="200px" />
            ) : (
              <Iconify icon={'solar:shield-cross-bold-duotone'} sx={{ mr: 2, color: 'error.main' }} width="200px" />
            )}

            <Stack spacing={2}>
              {bike && <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {`Bike: ${bike?.brand} ${bike?.model} ${bike?.year}`}
              </Typography>
              }

              {product && <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {`Product: ${product?.categoryName} - ${product?.sku} - ${product?.name}`}
              </Typography>
              }

              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {`Message: ${compatible ? 'Compatible' : 'Not Compatible'}. ${message}`}
              </Typography>
            </Stack>
          </StyledMessage>

        </Box>

      </Dialog>
    );
  }
  
  // CompatibilityDialog.propTypes = {
  //   onClose: PropTypes.func.isRequired,
  //   open: PropTypes.bool.isRequired,
  //   compatible: PropTypes.bool.isRequired,
  //   message: PropTypes.string.isRequired,
  // };