import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState } from 'react';
import { useLoaderData } from "react-router-dom";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import CompatibilityDialog from '../components/compatibility-dialog';
import MultiCompatibilityDialog from '../components/multi-compatibility-dialog';
// sections
import { ProductSort, ProductListHead, ProductListToolbar, ProductFilterSidebar } from '../sections/@dashboard/product';
// mock
// import BIKELIST from '../_mock/product';

// import { getProducts } from "../services/fetchProducts";
import { checkCompatibilityBySku, checkCompatibilityBySkus } from "../services/fetchCompatibility";

// bike context
import { BikeContext } from '../bikeContext';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'categoryId', label: 'Category Id', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'sku', label: 'Sku', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_product) => _product.sku?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    || _product.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ProductPage() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [bikeCheckOpen, setBikeCheckOpen] = useState(false);
  const [bikeMultiCheckOpen, setMultiBikeCheckOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedBikeFilterChecked, setSelectedBikeFilterChecked] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleSelectedBikeChange = () => {
    
    clearSelected();

    const currentBikeFilterChecked = !selectedBikeFilterChecked;

    setSelectedBikeFilterChecked(currentBikeFilterChecked);

    // if it's now checked, then filter the results, otherwise, clear the filter
    if (currentBikeFilterChecked){

      // get the skus to check
      // const selectedProducts = productsData.filter(product => selected.includes(product.id));
      const skus = productsData.map(product => product.sku).join(',');

      checkCompatibilityBySkus(selectedBike?.bike_key, skus).then(data => {

        console.log(data?.compatibleSkus.split(',').map(sku => sku.trim()));
        // console.log(productsData.map((product, index) => ({id: index, key: index.toString(), categoryId: product.product_Category_ID, categoryName: product.product_Category_Name, sku: product.sku, name: product.name})));

        // setProducts(productsData.filter(product => data?.compatibleSkus.includes(product.sku)).map((product, index) => ({id: index, key: index.toString(), categoryId: product.product_Category_ID, categoryName: product.product_Category_Name, sku: product.sku, name: product.name})))
        setProducts(productsData.filter(product => data?.compatibleSkus.includes(product.sku)));
        
        // setMultiCompatibleResult(data?.compatibleSkus.split(',').map(sku => sku.trim()))
        // setMultiCompatibleMessage(data?.compatibilityMessage)
      });    


    } else {

      setProducts(products.map((product, index) => ({id: index, key: index.toString(), categoryId: product.product_Category_ID, categoryName: product.product_Category_Name, sku: product.sku, name: product.name})))


    }

  };


  
  
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [menuProduct, setMenuProduct] = useState(null);
  const {selectedBike, setSelectedBike} = useContext(BikeContext);

  const [productsData, setProducts] = useState([]);
  // useEffect(() => { 
  //   getProducts().then(data => {
  //     setProducts(data.map((product, index) => ({id: index, key: index.toString(), categoryId: product.product_Category_ID, categoryName: product.product_Category_Name, sku: product.sku, name: product.name})))
  //   })
  // }, []);

  const products = useLoaderData();
  useEffect(() => {
    console.log("product page use effect"); 
    setProducts(products.map((product, index) => ({id: index, key: index.toString(), categoryId: product.product_Category_ID, categoryName: product.product_Category_Name, sku: product.sku, name: product.name})));
    clearSelected();
    setSelectedBikeFilterChecked(false);
  }, [products]);


  const [compatibleResult, setCompatibleResult] = useState(0);
  const [compatibleMessage, setCompatibleMessage] = useState('');
  const fetchCompatibilityCheck = (sku) => {

    checkCompatibilityBySku(selectedBike.bike_key, sku).then(data => {
      // console.log(data);
      setCompatibleResult(data?.compatible)
      setCompatibleMessage(data?.compatibilityMessage)
      handleBikeCheckOpen();
    })

  };


  const [selectedProducts, setSelectedProducts] = useState(null);
  const [multiCompatibleResult, setMultiCompatibleResult] = useState('');
  const [multiCompatibleMessage, setMultiCompatibleMessage] = useState('');
  const fetchMultiCompatibilityCheck = (skus) => {

    checkCompatibilityBySkus(selectedBike.bike_key, skus).then(data => {
      // console.log(data);
      setMultiCompatibleResult(data?.compatibleSkus.split(',').map(sku => sku.trim()))
      setMultiCompatibleMessage(data?.compatibilityMessage)
      handleMultiBikeCheckOpen();
    })    

  };  

  const handleCheckCompatibility = (product) => {

    fetchCompatibilityCheck(product?.sku);

    // console.log(`Selected Sku: ${product?.sku}`);
    handleCloseMenu();
    // handleBikeCheckOpen();
  };

  const handleCheckMultiCompatibility = () => {

    // get the multiple skus
    // console.log(selected);
    const selectedProducts = productsData.filter(product => selected.includes(product.id))
    const selectedSkus = selectedProducts.map(product => product.sku).join(',');

    // console.log(selectedProducts);
    // console.log(selectedSkus);

    setSelectedProducts(selectedProducts);
    fetchMultiCompatibilityCheck(selectedSkus);

    // console.log(`Selected Sku: ${multiSkus}`);
    // handleMultiBikeCheckOpen();
  };

  const handleMultiBikeCheckOpen = () => {
    setMultiBikeCheckOpen(true);
  };
  
  const handleMultiBikeCheckClose = () => {
    setMultiBikeCheckOpen(false);
  };

  const handleBikeCheckOpen = () => {
    setBikeCheckOpen(true);
  };
  
  const handleBikeCheckClose = () => {
    setBikeCheckOpen(false);
  };

  const handleOpenMenu = (event, product) => {
    setMenuProduct(product);
    setMenuOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = productsData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    clearSelected();
  };

  const clearSelected = () => {
    setSelected([]);
  };  

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productsData.length) : 0;

  const filteredProducts = applySortFilter(productsData, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredProducts.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Products | Bike Matrix </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Product
          </Button> */}
        </Stack>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              handleSelectedBikeChange={handleSelectedBikeChange}
              selectedBikeFilterChecked={selectedBikeFilterChecked}
            />
            <ProductSort />
          </Stack>
        </Stack>

        <Card>
          <ProductListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} handleCheckMultiCompatibility={handleCheckMultiCompatibility} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ProductListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={productsData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, key, categoryId, categoryName, sku, name } = row;
                    const selectedProduct = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedProduct}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedProduct} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell align="left">{categoryId}</TableCell>

                        <TableCell align="left">{categoryName}</TableCell>

                        <TableCell align="left">{sku}</TableCell>
                        
                        <TableCell align="left">{name}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, {categoryId, categoryName, sku, name})}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={productsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(menuOpen)}
        anchorEl={menuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

        <MenuItem
          onClick={() => handleCheckCompatibility(menuProduct)}
        >
          <Iconify icon={'material-symbols:fact-check-outline'} sx={{ mr: 2 }} />
          Compatibile?
        </MenuItem>

        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>


      <MultiCompatibilityDialog
        title="Bike Compatibility Check"
        compatibleSkus={multiCompatibleResult}
        message={multiCompatibleMessage}
        bike={selectedBike}
        products={selectedProducts}
        open={bikeMultiCheckOpen}
        onClose={handleMultiBikeCheckClose}
      />

      <CompatibilityDialog
        title="Bike Compatibility Check"
        compatible={compatibleResult}
        message={compatibleMessage}
        bike={selectedBike}
        product={menuProduct}
        open={bikeCheckOpen}
        onClose={handleBikeCheckClose}
      />

    </>
  );
}
