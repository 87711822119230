import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';

import Iconify from '../iconify';

// ----------------------------------------------------------------------

const StyledMessage = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function MultiCompatibilityDialog(props) {
    const { onClose, title, compatibleSkus, message, open, bike, products } = props;
  
    const handleClose = () => {
      onClose();
    };
  
    // console.log(products);

    return (
      <Dialog onClose={handleClose} open={open}>

        <DialogTitle>{title}</DialogTitle>
        <Box sx={{ mb: 2.5, mx: 2.5 }}>
            
          <StyledMessage>

            {/* icons from https://icon-sets.iconify.design/solar/shield-check-bold-duotone/ */}

            <Stack spacing={2}>
              {bike && <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {`${bike?.brand} ${bike?.model} ${bike?.year}`}
              </Typography>
              }

              {!Array.isArray(products) || !products ? (
                <p/>
              ) : (
                <List dense>
                  {products.map(product => (
                    <ListItem key={product.sku}>
                      <ListItemAvatar>
                          {compatibleSkus?.includes(product.sku) ? ( 
                            <Iconify icon={'solar:shield-check-bold-duotone'} sx={{ mr: 2, color: 'success.main' }} width="24px" />
                          ) : (
                            <Iconify icon={'solar:shield-cross-bold-duotone'} sx={{ mr: 2, color: 'error.main' }} width="24px" />
                          )}
                      </ListItemAvatar>
                      <ListItemText nowrap="true" primary={`${product.sku} - ${product.name}`}/>
                    </ListItem>
                  ))}

                </List>

              )}



              {/* {products && products.map(product => { return(
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {`Product: ${product?.categoryName} - ${product?.sku} - ${product?.name}`}
                </Typography>
              )})} */}

              {/* {products && products.map(product => (
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {`Product: ${product?.categoryName} - ${product?.sku} - ${product?.name}`}
                </Typography>
              ))} */}

              {/* {(products && products.map(product => <div>{product.name}</div>))} */}




              <Typography variant="subtitle2" sx={{ color: 'text.primary', whiteSpace: 'normal'}} >
                {`${message && message}`}
              </Typography>
            </Stack>
          </StyledMessage>

        </Box>

      </Dialog>
    );
  }
  
  // MultiCompatibilityDialog.propTypes = {
  //   onClose: PropTypes.func.isRequired,
  //   open: PropTypes.bool.isRequired,
  //   compatibleSkus: PropTypes.string.isRequired,
  //   message: PropTypes.string.isRequired,
  // };